@import 'core/variables/colors';

.zentis-video-wrap {
  position: relative;
  padding-bottom: 56.25%;
}

.zentis-internal-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;

  &::-webkit-media-controls-overlay-play-button,
  &::-webkit-media-controls-start-playback-button {
    display: none !important;
    appearance: none;
  }


}
//video::-webkit-media-controls-overlay-play-button,
//video::-webkit-media-controls-start-playback-button {
//  display: none !important;
//  appearance: none;
//}

.zentis-internal-video-wrap {

  .play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 2.5em 0 2.5em 5em;
    border-color: transparent transparent transparent #fff;
    cursor: pointer;

    &:hover {
      border-color: transparent transparent transparent $zentis-red;
    }
  }

  &[data-state="play"] {
    .play-button {
      display: none;
    }
  }
}

.zentis-external-video {
  position: relative;
  padding-bottom: 56.25%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  outline: none;

  .video_trigger {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background-color: rgba(0, 0, 0, .7);
    color: #fff;

    p {
      margin: 15px auto;
      color: white;
      padding: 0 32px;
      max-width: 550px;
    }

    .play-button {
      position: relative;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 2.5em 0 2.5em 5em;
      border-color: transparent transparent transparent #fff;
      opacity: .75;

      &:hover {
        border-color: transparent transparent transparent $zentis-red;
      }
    }
  }

  .video_layer {
    display: none;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}




